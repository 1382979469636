import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";
import {
  Button,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  Box,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemText,
  List,
} from "@mui/material";
import RadioAuthFilter from "../../components/RadioAuthFilter";
import MyHeaderTableCell from "./MyHeaderTableCell";
import StyledTooltip from "../../components/StyledTooltip";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserStore from "../../../services/userStore";
import {
  getFilteredProfileResources,
  getInheritedResourceStatusValue,
  getResourceSelectValue,
  sortObjectsAlphabetically,
} from "../../../services/Helpers";
import useDebouncedValue from "../../../hooks/useDebouncedValue";
import { BsFiletypeExe } from "react-icons/bs";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { debounce } from "lodash";

const ProfilesAppsTable = ({
  selectedProfile,
  selectedPublisher,
  showCount,
  setShowCount,
  tenantName,
  handleClose,
  handleOpen,
  isBoxVisible,
}) => {
  const tableRef = useRef();
  const queryClient = useQueryClient();
  const userEmail = useUserStore((state) => state.user.email);
  const [apps, setApps] = useState([]);
  const [filterAppsValue, setFilterAppsValue] = useState("ALL");
  const [appsSearchValues, setAppsSearchValues] = useState("");

  const debouncedSearchTerm = useDebouncedValue(appsSearchValues, 1000);
  const [selectedApp, setSelectedApp] = useState(null);
  const [reputationEnabled, setReputationEnabled] = useState(false);

  const getApplications = async (
    selectedProfileOs,
    selectedProfileId,
    selectedPublisherId,
  ) => {
    const appArr = [];

    const getUrl = (publisherId) =>
      `${NGROK}/api/${selectedProfileOs}/profiles/${selectedProfileId}/publishers/${publisherId}/apps`;

    const fetchData = async (url) => {
      const result = await axios.get(url);
      if (result.data) {
        appArr.push(result.data.content);
      }
    };

    if (selectedPublisher.friendlyName !== "Unsigned Apps") {
      await Promise.all(
        selectedPublisher.ids.map((publisherId) =>
          fetchData(getUrl(publisherId)),
        ),
      );
    } else {
      await fetchData(getUrl(selectedPublisherId));
    }
    const appsSortedById = appArr.flat().sort((a, b) => a.appId - b.appId);
    return appsSortedById;
  };

  const getAllApplications = async (selectedProfileId) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/profiles/get-all-apps?profileId=${selectedProfileId}`,
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const changeApplicationStatus = async ({ app, groupStatus }) => {
    const application = app?.defaultProfileAppsResource;
    try {
      return await axios.put(`${NGROK}/api/profiles/apps/update`, {
        appId: application.appId,
        email: userEmail,
        fingerprintSha256: application.fingerprintSha256,
        groupStatus,
        profileId: application.profileId,
        tenantName,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const findApplication = (value) => {
    setAppsSearchValues(value);
  };

  const handleFilterResourceValue = (e) => {
    setFilterAppsValue(e.target.value);
  };

  const allAppsParameteresAreReady =
    !!selectedProfile &&
    !!selectedPublisher &&
    !!(selectedPublisher.id === "allAppsPublisher");

  const profileAppsParameteresAreReady =
    !!selectedProfile &&
    !!selectedPublisher &&
    !!(selectedPublisher.id !== "allAppsPublisher");

  const { data: allApps, isLoading: allAppsAreLoading } = useQuery({
    queryKey: ["allProfilesApps", selectedProfile.id],
    queryFn: () => getAllApplications(selectedProfile.id),
    enabled: allAppsParameteresAreReady,
  });

  const { data: profileApps, isLoading: appsAreLoading } = useQuery({
    queryKey: [
      "profileApps",
      selectedProfile.os,
      selectedProfile.id,
      selectedPublisher.id,
    ],
    queryFn: () =>
      getApplications(
        selectedProfile.os,
        selectedProfile.id,
        selectedPublisher.id,
      ),
    enabled: profileAppsParameteresAreReady,
  });

  const updateStatusMutation = useMutation({
    mutationFn: changeApplicationStatus,

    onSuccess: ({ data: newObj }) => {
      queryClient.setQueryData(
        ["allProfilesApps", selectedProfile.id],
        (oldQueryData) => {
          return oldQueryData?.map((item) =>
            item.appId === newObj.appId ? newObj : item,
          );
        },
      );

      queryClient.setQueryData(
        [
          "profileApps",
          selectedProfile.os,
          selectedProfile.id,
          selectedPublisher.id,
        ],
        (oldQueryData) => {
          return oldQueryData?.map((item) =>
            item.appId === newObj.appId ? newObj : item,
          );
        },
      );
    },
  });

  const unicApps = (() => {
    if (!Array.isArray(apps)) {
      console.log("apps is not an array or is undefined.");
      return [];
    }

    const seenPaths = new Set();
    return apps.reduce((accumulator, current) => {
      const path = current?.defaultProfileAppsResource?.appPath;
      if (path && !seenPaths.has(path)) {
        seenPaths.add(path);
        accumulator.push(current);
      }
      return accumulator;
    }, []);
  })();

  const filteredApplications = useMemo(
    () =>
      sortObjectsAlphabetically(
        getFilteredProfileResources(
          debouncedSearchTerm,
          filterAppsValue,
          unicApps,
          true,
        ),
        "appName",
        "defaultProfileAppsResource",
      ),
    [unicApps, debouncedSearchTerm, filterAppsValue, apps],
  );

  const handleRowSelect = (application) => {
    setSelectedApp((prevSelectedApp) =>
      prevSelectedApp &&
      prevSelectedApp?.defaultProfileAppsResource?.appId ===
        application?.defaultProfileAppsResource?.appId
        ? null
        : application,
    );
    handleOpen();
  };

  const getFormattedSize = (sizeInBytes) => {
    if (sizeInBytes >= 1024 * 1024 * 1024) {
      return (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    } else if (sizeInBytes >= 1024 * 1024) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
    } else if (sizeInBytes >= 1024) {
      return (sizeInBytes / 1024).toFixed(2) + " KB";
    } else {
      return sizeInBytes + " bytes";
    }
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const table = tableRef.current;
        const rows = table.querySelectorAll("tr");

        if (rows.length >= showCount) {
          const fiftiethRow = rows[showCount - 1];
          const rect = fiftiethRow.getBoundingClientRect();
          const tableRect = table.getBoundingClientRect();
          if (
            rect.top >= tableRect.top &&
            rect.bottom <= tableRect.bottom &&
            filteredApplications?.length > showCount
          ) {
            setShowCount((prevCount) => prevCount + 50);
          }
        }
      }
    };

    const tableContainer = tableRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tableRef, unicApps, showCount, filteredApplications, setShowCount]);

  useEffect(() => {
    if (allApps && selectedPublisher.id === "allAppsPublisher")
      setApps(allApps);
    else if (profileApps) setApps(profileApps);
    else setApps([]);
  }, [allApps, profileApps, selectedPublisher.id]);

  const getReputationConfig = async () => {
    const response = await axios.get(
      `${NGROK}/api/${tenantName}/generalsettings`,
    );
    const data = response?.data;
    const configValue = data?.find(
      (config) => config.settingsName === "fileReputationCheckEnabled",
    );
    if (configValue) {
      setReputationEnabled(true);
    } else {
      setReputationEnabled(false);
    }
  };

  useEffect(() => {
    getReputationConfig();
  }, [allApps, profileApps, selectedPublisher.id]);

  const debouncedUpdateStatus = useMemo(
    () =>
      debounce((app, groupStatus) => {
        updateStatusMutation.mutate({ app, groupStatus });
      }, 300),
    [],
  );

  const handleStatusChange = (application, newStatus) => {
    const resourceMapping = {
      ENABLED: "ALLOWED",
      DISABLED: "BLOCKED",
      DYNAMIC: "DYNAMIC",
      INHERITED: "INHERITED",
    };

    const updatedApplications = filteredApplications.map((app) =>
      app?.defaultProfileAppsResource?.appId ===
      application?.defaultProfileAppsResource?.appId
        ? {
            ...app,
            defaultProfileAppsResource: {
              ...app.defaultProfileAppsResource,
              groupStatus: newStatus,
              profileResource: resourceMapping[newStatus],
            },
          }
        : app,
    );

    setApps(updatedApplications);

    debouncedUpdateStatus(application, newStatus);
  };

  if (allAppsAreLoading || appsAreLoading) {
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box width={"100%"}>
      {unicApps?.length ? (
        <Stack spacing={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            sx={{ padding: "15px" }}
          >
            <RadioAuthFilter
              filterResourceValue={filterAppsValue}
              filterName={"Filter by privilege"}
              handleFilterResourceValue={handleFilterResourceValue}
            />
            <TextField
              sx={{ width: 250 }}
              label="Search..."
              id="searchForApplication"
              value={appsSearchValues}
              onChange={(e) => findApplication(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", width: "100%", flexGrow: 1 }}>
            <Stack direction="column" spacing={2} width="100%">
              <Typography
                variant="h6"
                color="initial"
                fontWeight={600}
                align="center"
              >
                Total Count: {filteredApplications?.length || "0"}
              </Typography>
              <TableContainer
                component={Paper}
                ref={tableRef}
                sx={{
                  height: "fit-content !important",
                  maxHeight: "100vh !important",
                  overflowY: "auto !important",
                }}
              >
                <Table
                  sx={{
                    width: "100%",
                    height: "fit-content",
                    "& td, & th": {
                      border: "1px solid #233044",
                    },
                  }}
                  size="large"
                >
                  <TableHead>
                    <TableRow>
                      <MyHeaderTableCell
                        align={"center"}
                        className={"userName"}
                      >
                        Application
                      </MyHeaderTableCell>
                      <MyHeaderTableCell
                        align={"center"}
                        className={"userName"}
                      >
                        Trust level
                      </MyHeaderTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredApplications
                      .slice(0, showCount)
                      ?.map((application) => {
                        const appId =
                          application?.defaultProfileAppsResource?.appId;

                        return (
                          <TableRow
                            className="groupRow"
                            key={appId}
                            onClick={() => handleRowSelect(application)}
                            selected={
                              selectedApp?.defaultProfileAppsResource?.appId ===
                              appId
                            }
                            sx={{
                              "&:hover": {
                                background: "#f5f5f5",
                                cursor: "pointer",
                              },
                              "& td, & th": {
                                fontSize: { xs: "10px", md: "12px" },
                              },
                              "&.Mui-selected": {
                                background: "#233044 !important",
                                "& td, & th": {
                                  color: "#ffffff !important",
                                },
                                "& svg": {
                                  color: "#ffffff",
                                },
                              },
                            }}
                          >
                            <StyledTooltip
                              arrow
                              title={
                                <>
                                  <Typography color="inherit">
                                    {`Path: ${application?.defaultProfileAppsResource?.appPath}`}
                                  </Typography>
                                  {/* <br />
                            <Typography color="inherit">
                              {`Size: ${application.size}`}
                            </Typography>
                            <br /> */}
                                  {/* <Typography color="inherit">
                              {`Fingerprint: ${application.fingerprintSha256}`}
                            </Typography> */}
                                </>
                              }
                              placement="left"
                            >
                              <TableCell className="userName">
                                <Stack
                                  direction="row"
                                  spacing={2}
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  {application?.applicationReputation ? (
                                    <span
                                      style={{
                                        display: "inline-block",
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        backgroundColor:
                                          application?.applicationReputation
                                            ?.score === 0
                                            ? "green"
                                            : application?.applicationReputation
                                                  ?.score > 0 &&
                                                application
                                                  ?.applicationReputation
                                                  ?.score <= 50
                                              ? "yellow"
                                              : application
                                                    ?.applicationReputation
                                                    ?.score > 50 &&
                                                  application
                                                    ?.applicationReputation
                                                    ?.score <= 75
                                                ? "orange"
                                                : application
                                                      ?.applicationReputation
                                                      ?.score > 75
                                                  ? "red"
                                                  : "green",
                                      }}
                                    />
                                  ) : null}

                                  <BsFiletypeExe style={{ fontSize: "1rem" }} />
                                  <span
                                    style={{
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: "150px",
                                    }}
                                  >
                                    {
                                      application?.defaultProfileAppsResource
                                        ?.appName
                                    }
                                  </span>
                                </Stack>
                              </TableCell>
                            </StyledTooltip>
                            <TableCell>
                              <Box className="profilePrivilegeLevelTableCell">
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={getResourceSelectValue(
                                    application?.defaultProfileAppsResource,
                                  )}
                                  size="small"
                                  onClick={(event) => event.stopPropagation()}
                                  onChange={(event) => {
                                    handleStatusChange(
                                      application,
                                      event.target.value,
                                    );
                                  }}
                                  sx={{
                                    minWidth: "100px",
                                    color:
                                      selectedApp?.defaultProfileAppsResource
                                        ?.appId ===
                                      application?.defaultProfileAppsResource
                                        ?.appId
                                        ? "white"
                                        : "black",
                                    "& .MuiSvgIcon-root": {
                                      color:
                                        selectedApp?.defaultProfileAppsResource
                                          ?.appId ===
                                        application?.defaultProfileAppsResource
                                          ?.appId
                                          ? "white"
                                          : "black",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor:
                                        selectedApp?.defaultProfileAppsResource
                                          ?.appId ===
                                        application?.defaultProfileAppsResource
                                          ?.appId
                                          ? "white"
                                          : "black",
                                    },
                                  }}
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        bgcolor: "#233044",
                                        "& .MuiMenuItem-root": {
                                          color: "white !important",
                                        },
                                        "& .MuiMenuItem-root.Mui-selected": {
                                          bgcolor: "#233044 !important",
                                          color: "white !important",
                                        },
                                      },
                                    },
                                  }}
                                >
                                  <MenuItem value={"ENABLED"}>ALLOWED</MenuItem>
                                  <MenuItem value={"DISABLED"}>DENIED</MenuItem>
                                  <MenuItem value={"DYNAMIC"}>TIMED</MenuItem>
                                  {application?.defaultProfileAppsResource
                                    ?.trustLevel ? (
                                    <MenuItem value={"INHERITED"}>
                                      INHERITED{" "}
                                      {getInheritedResourceStatusValue(
                                        application?.defaultProfileAppsResource,
                                      )}
                                    </MenuItem>
                                  ) : null}
                                </Select>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {filteredApplications?.length > showCount ? (
                      <TableRow sx={{ border: 1 }}>
                        <TableCell colSpan={7} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
            {selectedApp &&
              isBoxVisible &&
              (() => {
                const sizeInBytes =
                  selectedApp?.defaultProfileAppsResource?.size || 0;
                const formattedSize = getFormattedSize(sizeInBytes);

                return (
                  <Box
                    sx={{
                      mt: 6.5,
                      ml: 2,
                      width: "350px",
                      display: "flex",
                      flexDirection: "column",
                      border: "1px solid",
                      flexShrink: 0,
                      bgcolor: "#f9f9f9",
                      borderRadius: "5px",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                      position: "relative",
                    }}
                  >
                    <IconButton
                      onClick={handleClose}
                      sx={{
                        position: "absolute",
                        top: 3,
                        right: 8,
                        color: "#fff",
                        backgroundColor: "#233044",
                        "&:hover": {
                          backgroundColor: "#1c2735",
                        },
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        background: "#233044",
                        color: "#fff",
                        padding: "10px",
                        borderRadius: "5px 5px 0 0",
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      Details
                    </Typography>

                    <List dense sx={{ padding: 2 }}>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Box
                              display="flex"
                              flexDirection="row"
                              flexWrap="wrap"
                            >
                              <Typography sx={{ fontSize: 16, marginRight: 1 }}>
                                <strong>Name: </strong>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  overflowWrap: "break-word",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                  flex: 1,
                                  minWidth: 0,
                                }}
                              >
                                {
                                  selectedApp?.defaultProfileAppsResource
                                    ?.appName
                                }
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={
                            <Box
                              display="flex"
                              flexDirection="row"
                              flexWrap="wrap"
                            >
                              <Typography sx={{ fontSize: 16, marginRight: 1 }}>
                                <strong>Path: </strong>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  overflowWrap: "break-word",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                  flex: 1,
                                  minWidth: 0,
                                }}
                              >
                                {
                                  selectedApp?.defaultProfileAppsResource
                                    ?.appPath
                                }
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={
                            <Box
                              display="flex"
                              flexDirection="row"
                              flexWrap="wrap"
                            >
                              <Typography sx={{ fontSize: 16, marginRight: 1 }}>
                                <strong>SHA-256: </strong>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  overflowWrap: "break-word",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                  flex: 1,
                                  minWidth: 0,
                                }}
                              >
                                {
                                  selectedApp?.defaultProfileAppsResource
                                    ?.fingerprintSha256
                                }
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>

                      <ListItem>
                        <ListItemText
                          primary={
                            <Box
                              display="flex"
                              flexDirection="row"
                              flexWrap="wrap"
                            >
                              <Typography sx={{ fontSize: 16, marginRight: 1 }}>
                                <strong>Size: </strong>
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  overflowWrap: "break-word",
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                  flex: 1,
                                  minWidth: 0,
                                }}
                              >
                                {formattedSize}
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>

                      {selectedApp?.applicationReputation?.score > 0 && (
                        <>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Box display="flex" flexDirection="column">
                                  <Typography sx={{ fontSize: 16 }}>
                                    <strong>Verdict:</strong>{" "}
                                    {
                                      selectedApp?.applicationReputation
                                        ?.verdict
                                    }
                                  </Typography>
                                </Box>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Box display="flex" flexDirection="column">
                                  <Typography sx={{ fontSize: 16 }}>
                                    <strong>Status:</strong>{" "}
                                    {
                                      selectedApp?.applicationReputation
                                        ?.rawData?.status
                                    }
                                  </Typography>
                                </Box>
                              }
                            />
                          </ListItem>

                          {selectedApp?.applicationReputation?.catagory
                            ?.length > 0 && (
                            <ListItem>
                              <ListItemText
                                primary={
                                  <Typography sx={{ fontSize: 16 }}>
                                    <strong>Category:</strong>{" "}
                                    {selectedApp.applicationReputation.catagory.join(
                                      ", ",
                                    )}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          )}

                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>Platform:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.classification?.platform || "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>Type:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.classification?.type || "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>FamilyName:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.classification?.familyName || "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>ThreatName:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.threatName || "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>Reason:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.reason || "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>Scanner Percent:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.scannerPercent || "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>First seen:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.firstSeen
                                    ? moment(
                                        selectedApp?.applicationReputation
                                          ?.rawData?.firstSeen,
                                      ).format("YYYY-MM-DD HH:mm")
                                    : "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>Last seen:</strong>{" "}
                                  {selectedApp?.applicationReputation?.rawData
                                    ?.lastSeen
                                    ? moment(
                                        selectedApp?.applicationReputation
                                          ?.rawData?.lastSeen,
                                      ).format("YYYY-MM-DD HH:mm")
                                    : "N/A"}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </>
                      )}
                    </List>
                  </Box>
                );
              })()}
          </Box>
        </Stack>
      ) : (
        <Typography variant="h6">There are no applications.</Typography>
      )}
    </Box>
  );
};

export default ProfilesAppsTable;
